@import "style/colors";

html body {
  font-family: sans-serif;
  color: $he-main-text;

  --mdc-typography-body1-font-size: 13px;
  --mdc-typography-body1-line-height: 13px;
  --mdc-typography-button-font-size: 13px;
  --mdc-typography-button-font-weight: 400;
  --mdc-typography-button-letter-spacing: 0px;
  --mdc-typography-subtitle1-font-size: 13px;
  --mdc-typography-subtitle1-letter-spacing: 0px;
  --mdc-elevated-card-container-shape: 8px;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled):not(.he-chip-void)
  .mdc-evolution-chip__text-label {
  --mdc-chip-label-text-color: #{$he-green}; // string interpolation:
}

.mat-mdc-button,
.mat-icon-button,
.mat-stroked-button {
  // Buttons without a background color should inherit the font color. This is necessary to
  // ensure that the button is readable on custom background colors. It's wrong to always assume
  // that those buttons are always placed inside of containers with the default background
  // color of the theme (e.g. themed toolbars).
  color: inherit;
  background: transparent;
}

.mdc-button.mat-button-disabled {
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38) !important;
}

.mat-mdc-card-content:not(.regular-padding) {
  padding: 24px !important;
}

.mdc-tooltip__surface {
  max-width: 250px !important;
}

.mat-card-header-text {
  margin: 0 0 !important;
}
.record-count {
  margin-top: 8px;
}
.horizon-card {
  padding: 2px;
  border-radius: 2px !important;
  .mat-mdc-card-header:first-child {
    padding: 0;
  }
  mat-card-content:first-child {
    padding: 0;
  }
  .mat-mdc-card-content:last-child {
    padding: 0 !important;
  }
  .mat-sub-card {
    padding: 16px !important;
    border-radius: 4px;
  }
  .mat-mdc-card-avatar {
    margin-bottom: 0;
  }
}
.mdc-button {
  padding: 0 16px !important;
}
.mdc-button__label {
  display: flex;
  align-items: center;
}
.record-count {
  margin-top: 8px;
}
.blue-snackbarBG {
  background: #cdcce9 !important;
}

.horizon-field {
  .mat-mdc-form-field {
    margin-top: -12px !important;
    padding-bottom: 8px !important;
    background-color: inherit !important;
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: inherit !important;
    }
  }
  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
  .mdc-text-field {
    padding: 0 0 !important;
  }
  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
  .mat-mdc-form-field-infix {
    min-height: 44px !important;
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    background: inherit !important;
    padding-top: 24px !important;
    padding-bottom: 0px !important;
  }
  .mat-mdc-select-arrow svg {
    top: 213%;
    left: 19%;
  }
  .mdc-menu-surface.mat-mdc-select-panel {
    padding: 0 0 !important;
  }
  .mat-mdc-option {
    min-height: 36px !important;
  }
}

.horizon-field-02 {
  .mat-mdc-form-field {
    padding-bottom: 8px;
    background-color: inherit !important;
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: inherit;
    }
  }
  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }
  .mdc-text-field {
    padding: 0 0;
  }
  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }
  .mat-mdc-form-field-infix {
    min-height: 44px;
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    background: inherit !important;
    padding-top: 22px !important;
    padding-bottom: 3px !important;
  }
  .mat-mdc-select-arrow svg {
    // top: 213%;
    left: 0%;
  }
  .mdc-menu-surface.mat-mdc-select-panel {
    padding: 0 0;
  }
  .mat-mdc-option {
    min-height: 36px !important;
  }
}

.horizon-field-03 {
  .mat-mdc-form-field {
    margin-top: -12px !important;
    padding-bottom: 0px !important;
    background-color: inherit !important;
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: inherit !important;
    }
  }
  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
  .mdc-text-field {
    padding: 0 0 !important;
  }
  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
  .mat-mdc-form-field-infix {
    min-height: 44px !important;
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    background: inherit !important;
    padding-top: 24px !important;
    padding-bottom: 0px !important;
  }
  .mat-mdc-select-arrow svg {
    top: 213%;
    left: 19%;
  }
  .mdc-menu-surface.mat-mdc-select-panel {
    padding: 0 0 !important;
  }
  .mat-mdc-option {
    min-height: 36px !important;
  }
}
.horizon-error {
  .mdc-snackbar__label {
    color: #f20e0e !important;
    p{
      margin:0px !important;
    }
    mat-icon {
      background-color: #fbc1bd;
      border-radius: 50%;
      color: #f20e0e;
      margin-right: 5px;
      min-width: 4%;
    }

  }
  .mdc-snackbar__surface {
    background-color: #fbc1bd !important;
  }
}

.horizon-error-2FA {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -65%) !important;
  z-index: 1000 !important;
  opacity: 1 !important;
  .mdc-snackbar__label {
    color: #f20e0e !important;
    p{
      margin:0px !important;
    }
    mat-icon {
      background-color: #fbc1bd;
      border-radius: 50%;
      color: #f20e0e;
      margin-right: 5px;
      min-width: 4%;
    }

  }
  .mdc-snackbar__surface {
    background-color: #fbc1bd !important;
  }
}

.horizon-success {
  .mdc-snackbar__label {
    color: #374050 !important;
    p{
      margin:0px !important;
    }
    mat-icon {
      background-color: #cecdea;
      border-radius: 50%;
      color: #7773b3;
      margin-right: 5px;
    }
  }
  .mdc-snackbar__surface {
    background-color: #cecdea !important;
  }
}
.horizon-success-2FA {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -65%) !important;
  z-index: 1000 !important;
  opacity: 1 !important;
  .mdc-snackbar__label {
    color: #374050 !important;
    p{
      margin:0px !important;
    }
    mat-icon {
      background-color: #cecdea;
      border-radius: 50%;
      color: #7773b3;
      margin-right: 5px;
    }
  }
  .mdc-snackbar__surface {
    background-color: #cecdea !important;
  }
}

.horizon-text-ellipsis{
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.horizon-tooltip {
  .mdc-tooltip__surface-animation {
    background-color: #cecdea !important;
    color: black;
  }
}
.horizon-tooltip-02 {
  .mdc-tooltip__surface-animation {
    background-color: #cecdea !important;
    color: #4d488c;
    font-size: 16px;
    padding: 10px;
    width: 150px;
    text-align: left;
  }
}
.gloabl-spinner-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: transparent;
    box-shadow:none;
  }
}
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

.delete-project-modal{
  position: relative;
  top: 40%;
  transform: translateY(-50%) !important;
}
.delete-project-modal .decline-btn{
  background-color: $horizon-button !important;
  color: $white !important;
  border-color: $horizon-button !important;
  outline: none !important;
  box-shadow: none;
}
.delete-project-modal .confirm-btn{
  border: 1px solid lightgray !important;
  color: $horizon-button !important;
  font-weight: 700;
  margin-right: 10px;
}

.mat-mdc-menu-panel.he-menu-panel-filter .mat-mdc-menu-content:not(:empty) {
  padding: 0 !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.he-menu-panel-filter {
  overflow: hidden;
  min-height: auto !important;

  .he-mat-select {
    font-family: sans-serif;
    width: 180px !important;
    background: $he-background;

    &:after {
      content: "";
      border: solid $he-additional-text;
      border-width: 0 2px 2px 0;
      display: inline-block;
      right: 14px;
      position: absolute;
      padding: 4px;
      transition-duration: 100ms;

      top: 14px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    .mat-mdc-select-trigger {
      border-radius: 8px;
      padding: 11px 24px 7px 12px;
      line-height: 16px;
      border: 1px solid transparent;
      cursor: pointer;
      margin-bottom: 4px;
    }

    .mat-mdc-select-arrow-wrapper {
      display: none;
    }
  }

  .he-mat-select[aria-expanded="true"] {
    &:after {
      top: 18px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  }
}

.mdc-menu-surface.mat-mdc-select-panel.he-mat-select-panel-filter {
  overflow-x: hidden !important;
  padding: 4px 0 !important;
}
.mat-mdc-option.mdc-list-item.he-mat-option.mdc-list-item--selected {
  background: none !important;
  font-weight: bold;
}
.mat-mdc-option.mdc-list-item.he-mat-option {
  font-family: sans-serif;
  width: 180px !important;
  min-height: 32px !important;
  padding-left: 8px;
  padding-right: 8px;

  .mdc-list-item__primary-text {
    line-height: 16px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%;

    div {
      flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

}
.mat-mdc-option.mdc-list-item.he-mat-option:hover {
  background: rgba(78, 70, 145, 0.1) !important;
  color: #4E4691 !important;
  font-weight: bold;

  .mdc-list-item__primary-text {
    color: #4E4691 !important;
  }
}

.Available{
  color:$pulse-available-highlight;
  font-weight: 600;

}
.Approved{
  color:$pulse-approved-highlight;
  font-weight: 600;

}