$white: #ffffff;
$black: #000000;
$green: #00A777;
$orange: #E89806;
$red: #e93c3c;
$he-main: #4E4691;
$he-main-text: #354052;
$he-links: #1A7BC0;
$he-green: #4CA9B5;
$he-additional-text: #92a1b9;
$he-additional-text-dark: #8394af;
$he-background: #f2f3f6;
$he-background-dark: #e1e3ea;
$he-card-shadow: rgba(16, 30, 115, 0.04);
$formal-ref-status: #4CA9B5;
$informal-ref-status: #9CDCE7;
$selected-country: #4E4691;
$horizon-blue: #0f699c;
$horizon-gray: gray;
$horizon-light-gray: lightgray;
$horizon-card-heading: #4D488C;
$horizon-card-background: #F9F9F9;
$horizon-tab-background: #F7F7F7;
$horizon-border: #F2F2F5;
$horizon-pill-button-border: #D9DEE9;
$horizon-pill-button-background: #F2F3F5;
$horizon-toggle-button-checked: #77839F;
$horizon-slider-thumb-border: rgba(181, 188, 201, 0.9);
$horizon-box-shadow: rgba(0, 0, 0, 0.25);
$horizon-table-row-highlight: #E7EFF3;

//V1-Design Changes
$horizon-banner : #252162;
$horizon-text-icon-color : #4D488C;
$horizon-highligths: #EDECF4;
$horizon-button : #716BC1;
$text-field-border: #f2f3f6;
$text-field-border-hover: #354052;
$irp-matrix-highlight: #f2f3f6;
$availability-2nd-row-highlight: #f7f8fb;
$he-selectedcolumn-background: #b1b6b7;
$he-tab-background: rgba(78, 70, 145, 0.1);

// Chip
$he-chip-border-color: #d9dee9;
$he-chip-background-color: #f2f3f5;
$he-chip-badge-color: #0f699c;

//availability status
$pulse-available-highlight:green;
$pulse-approved-highlight:#C65911;